import Logo from '../../../assets/images/favicon.jpg'
import {Card} from 'primereact/card'
import {useEffect, useState} from 'react'
import text from '../../../data/text.json'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {useNavigate, useParams} from 'react-router-dom'
import Auth from '../../../api/auth'
import {ProgressSpinner} from 'primereact/progressspinner'
import classNames from 'classnames'

const ConfirmAccount = () => {
    const [password, setPassword] = useState('')
    const [isValidating, setIsValidating] = useState(false)
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})
    const [passwordIsShow, setPasswordIsShow] = useState(false)
    const navigate = useNavigate()
    const {code} = useParams()
    if (!code) {
        navigate('/login')
    }
    const lang = 'az'

    const changePassword = async e => {
        e.preventDefault()
        setLoader(true)
        try {
            await Auth.verifyAccount({
                code,
                password
            })
            navigate('/login')
        } catch (e) {
            console.log('Error')
        } finally {
            setLoader(false)
        }
    }

    const validateCode = async() => {
        setIsValidating(true)
        try {
            const res = await Auth.findUnCompletedUser({code})
            setData(res)
        } catch (e) {
            navigate('/login')
        } finally {
            setIsValidating(false)
        }
    }

    useEffect(() => {
        validateCode()
    }, [])
    return (
        <div className="auth-page">
            {isValidating ? (
                <div className="flex align-items-center pt-5 justify-content-center">
                    <ProgressSpinner/>
                </div>
            ) : (
                <div className="grid">
                    <div className="col-12 md:col-4 md:col-offset-4">
                        <Card className="mt-5">
                            <div className="text-center">
                                <img src={Logo} width="150" height="100" alt="Qasım Bayramov"/>
                            </div>
                            <form onSubmit={changePassword}>
                                <div className="mb-2">
                                    <p>{data?.name} {data?.surname} (<b>{data?.email}</b>)</p>
                                </div>
                                <span className="p-input-icon-right w-full">
                                    <i onClick={() => setPasswordIsShow(!passwordIsShow)} className={classNames({
                                        'pi cursor-pointer': true,
                                        'pi-eye': !passwordIsShow,
                                        'pi-eye-slash': passwordIsShow
                                    })}/>
                                    <InputText
                                        className="w-full"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        id="password"
                                        aria-describedby="password"
                                        placeholder={text[lang].password}
                                        type={passwordIsShow ? 'text' : 'password'}
                                    />
                                </span>
                                <Button className="p-button-danger w-full inline-block mt-2" disabled={loader}>
                                    {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                                    {text[lang].confirm}
                                </Button>
                            </form>
                        </Card>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ConfirmAccount
